import axios from "axios";
import { KTIcon } from "../../../helpers";
import { Tooltip } from "react-tooltip";
import { useIntl } from "react-intl";
import { useDemoInstancesRequest } from "../core/DemoInstancesProvider";
import { DemoInstance } from "../core/_models";

const ActionsGoToEnvironment = (props: { 
  instance : DemoInstance;
}) => {
  const intl = useIntl();

  return (
    <div className="menu-item px-10">
      <a
        href={`https://${props.instance.instance_id}.demo.admin.insentric.net`}
        target="_blank"
        className="btn btn-icon btn-primary"
        data-tooltip-id={`goToEnvironment-${props.instance.id}`}
        aria-label={intl.formatMessage({ id: "DEMOINSTANCES.GOTO" })} 
        rel="noreferrer"
      >
        <KTIcon iconName="exit-right" className="fs-1 m-0 actionIcon " />
      </a>
      <Tooltip
        id={`managegoToEnvironment-${props.instance.id}`}
        key={`managegoToEnvironment-${props.instance.id}`}
        content={intl.formatMessage({ id: "DEMOINSTANCES.GOTO" })}
        place="top"
        variant="dark"
        style={{ zIndex: 100 }}
      />
    </div>
  );
};

export default ActionsGoToEnvironment;
